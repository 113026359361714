import React from 'react'

function MoreProjects() {
  return (
    <div>
      lol
    </div>
  )
}

export default MoreProjects
